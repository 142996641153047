<template>
  <div>
    <h3>Unsubscribe me from Notifications</h3>
    <p><br></p>
    <p v-if="!token">Please use the link in the notification mail to unsubscribe from the notifications</p>
    <p v-if="notification && !notification.unsubscribed">Remove <strong><i>{{notification.email}}</i></strong> from notification list</p>
    <p v-else>You unsubscribed from the notifications</p>
    <div v-if="notification && !notification.unsubscribed">
    <v-card flat>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            />
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-radio-group
                v-model="unsubscribeReason"
                column
                class="justify-center"
                mandatory
              >
                <v-radio
                  label="👍 Jab Finder helped me to book appointment"
                  color="green"
                  value="booked_jabfinder"
                ></v-radio>
                <v-radio
                  label="👌 Booked appointment using another service"
                  color="green"
                  value="booked_other"
                ></v-radio>
                <v-radio
                  label="😤 Too many notification email "
                  color="red"
                  value="too_many_notification"
                ></v-radio>
                <v-radio
                  label="Other"
                  color="amber"
                  value="other"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            />
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="success"
                v-on:click="unsubscribe"
              >Unsubscribe Me</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    </div>
  </div>
</template>

<script>
import 'vuefire'
import { firestore } from '@/firebase'
const notifications = firestore.collection('notifications')

export default {
  props: {
    token: String
  },
  data () {
    return {
      notification: null,
      unsubscribeReason: null
    }
  },
  watch: {
    token: {
      // call it upon creation too
      immediate: true,
      handler (token) {
        this.$bind('notification', notifications.doc(token))
      }
    }
  },
  methods: {
    unsubscribe () {
      this.$firestoreRefs.notification.update({
        unsubscribed: true,
        unsubscribedReason: this.unsubscribeReason
      })
    }
  }
}
</script>
