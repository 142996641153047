import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import AsyncComputed from 'vue-async-computed'
import Moment from 'vue-moment'
import Clipboard from 'v-clipboard'
import VueMeta from 'vue-meta'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { firestorePlugin, rtdbPlugin } from 'vuefire'

Vue.use(VueMeta)
Vue.use(firestorePlugin)
Vue.use(rtdbPlugin)
Vue.use(Clipboard)
Vue.use(AsyncComputed)
Vue.use(Moment)
Vue.use(VueReCaptcha, { siteKey: '6Ld-DwscAAAAAPQqM9obMF-A-CrmHNjXw31-hK4h' })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
