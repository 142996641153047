<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col class="mb-1">
        <h3 class="display-1 font-weight-bold mb-3">
          Find Pfizer Vaccine
        </h3>
        <p>Anywhere in Australia</p>
          <v-autocomplete
            con
            id="suburb_select"
            placeholder="Please select a suburb"
            style="max-width: 400px"
            class="mx-auto"
            :items="suburbs"
            item-text="n"
            item-value="i"
            v-model="selectedSuburb"
            @change="updateSuburbs"
          ></v-autocomplete>
        <v-chip
          v-for="item in selectedSuburbs"
          :key="item"
          class="mr-2"
          close
          @click:close="removeSuburb(item)"
        >
          {{ (suburbs.find(o => o.i === item)).n }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row align="baseline" justify="center" dense>
        <p style="margin-top: 0;margin-bottom: 0;vertical-align: middle;">
          Clinics within
        </p>
        <v-select
          class="x-input-select"
        v-model="distance"
        :items="distanceOptions"
        item-text="name"
        item-value="value"
        return-object
        solo
        flat
        dense
        ></v-select>
    </v-row>

    <ClinicList :suburbs="sanitizedSuburbs" :distance="distance" :key="clinicListKey"/>
  </v-container>
</template>

<script>
import suburbs from '@/components/suburb.json'
import ClinicList from '@/components/ClinicList'

export default {
  name: 'HelloWorld',
  components: {
    ClinicList
  },

  data: () => ({
    suburbs: suburbs,
    selectedSuburbs: [],
    selectedSuburb: '',
    distance: { name: '5 km', value: 5 },
    distanceOptions: [
      { name: '5 km', value: 5 },
      { name: '10 km', value: 10 },
      { name: '25 km', value: 25 },
      { name: '50 km', value: 50 },
      { name: '100 km', value: 100 }
    ],
    clinicListKey: 0
  }),
  watch: {
    distance: function () {
      this.clinicListKey++
    },
    selectedSuburb: function () {
      this.clinicListKey++
    }
  },
  computed: {
    sanitizedSuburbs () {
      const sanitizedSuburbs = []
      for (const suburb of this.selectedSuburbs) {
        sanitizedSuburbs.push(suburb.slice(0, 4))
      }
      return sanitizedSuburbs
    }
  },
  methods: {
    updateSuburbs (selected) {
      if (!this.selectedSuburbs.includes(selected)) {
        this.selectedSuburbs.push(selected)
      }
    },
    removeSuburb (removed) {
      this.selectedSuburbs = this.selectedSuburbs.filter(function (value, index, arr) {
        return value !== removed
      })
      this.selectedSuburb = ''
    }
  }
}
</script>
<style>
.x-input-select{
  width: 120px;
  flex: unset;
  margin: 0 5px;
}
.v-select__slot input{
  text-align: center;
}

</style>
