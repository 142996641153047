<template>
  <div class="text-center align-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="blue lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          :disabled="suburbs && suburbs.length < 1"
        >
          Notify Me
        </v-btn>
      </template>

      <template>
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-title class="text-h5 blue lighten-2 white--text" >
              Notify Me
            </v-card-title>

            <v-card-text class="text-left">
              Get notified when clinics around you available.
            </v-card-text>

            <v-text-field
              style="width: 80%;padding-left: 10%"
              outlined
              label="Enter your email address"
              :rules="[rules.email, rules.required]"
              v-model=email
            ></v-text-field>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="amber"
                text
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                :disabled="disable"
                @click="saveEmail"
              >
                Add my email
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { firestore } from '@/firebase'
const slugify = require('slugify')

export default {
  name: 'NotifyMe',
  props: {
    suburbs: Array,
    selectedSuburbs: Array
  },
  data () {
    return {
      disable: false,
      valid: true,
      dialog: false,
      email: '',
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      }
    }
  },
  methods: {
    async saveEmail () {
      if (this.$refs.form.validate()) {
        this.disable = true
        this.$recaptcha('login').then(async (token) => {
          const docs = await firestore.collection('notifications').where('email', '==', this.email).get()
          const data = {
            email: this.email,
            suburbs: this.suburbs,
            selectedSuburbs: this.selectedSuburbs,
            unsubscribed: false
          }
          if (docs.empty) {
            await firestore.collection('notifications').add(data)
          } else {
            docs.forEach(async (doc) => {
              await firestore.collection('notifications').doc(doc.id).set(data)
            })
          }
          /*
          firestore.collection('notifications').where('email', '==', this.email).get().then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, '=>', doc.data());
              await firestore.collection('notifications').doc(doc.id).set({
                email: this.email,
                suburbs: this.suburbs
              })
            })
          }).catch((error) => {
            console.log('Error getting documents: ', error)
          })
          */

          /*
          await firestore.collection('notifications').doc(slugify(this.email)).set({
            email: this.email,
            suburbs: this.suburbs
          })
          */
          this.dialog = false
        }).finally(() => {
          this.disable = false
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .centered-input input {
  padding-left: 15px;
}
</style>
