<template>
  <v-container>
    <v-row>
      <v-col>
        <NotifyMe :suburbs="suburbsNearby" :selected-suburbs="suburbs"></NotifyMe>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p v-if="filteredClinics && filteredClinics.length">Found {{filteredClinics.length}} {{filteredClinics.length>1?'clinics':'clinic'}}</p>
        <p v-else-if="suburbs.length>0">Sorry no clinics found within {{distance.name}}. Nearby clinics will appear automatically when Pfizer vaccine booking available. <br>
          Click <strong>Notify Me</strong> to receive email notification when Pfizer vaccine booking available in nearby clinic</p>
        <v-card
          v-if="filteredClinics && filteredClinics.length"
          class="mx-auto"
          max-width="700px"
        >
          <v-list two-line>
            <v-list-item-group
              active-class="pink--text"
              style="text-align: start"
              multiple
            >
              <template v-for="(item, index) in filteredClinics">
            <v-list-item
              :href="item.link"
              target="_blank"
              v-bind:key="index+'_1'"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.name"
                  class="font-weight-bold"
                ></v-list-item-title>

                <v-list-item-subtitle
                  class="text--primary"
                  v-html="getSuburbAndAvailability(item)"
                ></v-list-item-subtitle>

              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text v-text="'Updated ' + moment(item.lastUpdated).fromNow()"></v-list-item-action-text>
                <v-img
                  :src="require('@/assets/'+item.site+'.png')" width="70" max-height="16"/>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < filteredClinics.length - 1"
              :key="index"
            ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from '@/firebase'
import moment from 'moment'
import NotifyMe from '@/components/NotifyMe'

export default {
  name: 'ClinicList',
  components: { NotifyMe },
  props: {
    suburbs: Array,
    distance: null
  },
  data () {
    return {
      clinics: [],
      nearbySuburbsData: null,
      moment: moment,
      backdoor: 0
    }
  },
  methods: {
    getSuburbAndAvailability (item) {
      return item.suburb + (item.appointmentTime ? '&emsp;&emsp;&emsp;&emsp;Appointment available on ' + moment(item.appointmentTime).format('dddd, MMM Do, YYYY h:mm A') : '')
    }
  },
  // created () {
  // this.$rtdbBind('clinics', db.ref('clinics')).then(clinics => {
  // this.clinics = clinics
  // clinics
  // })
  // }
  /*
  async beforeMount () {
    console.log(this.suburbs)
    if (this.suburbs) {
      this.nearbySuburbsData = await import('@/components/postcodes/' + this.suburbs[0] + '.json')
      console.log(this.nearbySuburbsData)
    }
  },
  */
  firebase: {
    clinics: db.ref('clinics')
  },
  computed: {

  },
  watch: {
    clinics: function (oldList, newList) {
      // console.log(newList)
      this.backdoor++
    }
  },
  asyncComputed: {
    async filteredClinics () {
      const tmp = this.backdoor
      const filteredClinics = []
      let nearbySuburbsData = null
      let nearbySuburbs = await this.suburbsNearby
      for (const suburb of this.suburbs) {
        try {
          nearbySuburbsData = await import('@/components/postcodes/' + suburb + '.json')
          nearbySuburbs = nearbySuburbs.concat(nearbySuburbsData[this.distance.value])
        } catch (e) {
        }
      }
      // console.log(nearbySuburbs)
      for (const clinic of this.clinics) {
        if (nearbySuburbs.includes(clinic.postcode)) {
          filteredClinics.push(clinic)
        }
      }
      return filteredClinics
    },
    async suburbsNearby () {
      let nearbySuburbs = this.suburbs.slice()
      let nearbySuburbsData = null
      for (const suburb of this.suburbs) {
        try {
          nearbySuburbsData = await import('@/components/postcodes/' + suburb + '.json')
          nearbySuburbs = nearbySuburbs.concat(nearbySuburbsData[this.distance.value])
        } catch (e) {
        }
      }
      return [...new Set(nearbySuburbs)]
    }
  }
}
</script>
