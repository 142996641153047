import Firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/analytics'

// firebase init
const firebaseConfig = {
  apiKey: 'AIzaSyDlJU3Z-0Ap_89EigWxxrp0PDSyHFY9YC0',
  authDomain: 'jab-finder.firebaseapp.com',
  databaseURL: 'https://jab-finder-default-rtdb.firebaseio.com',
  projectId: 'jab-finder',
  storageBucket: 'jab-finder.appspot.com',
  messagingSenderId: '565192280309',
  appId: '1:565192280309:web:46b84ce8248f4a7b135dbc',
  measurementId: 'G-TQXTHXF68Q'
}
// Initialize Firebase
Firebase.initializeApp(firebaseConfig)
Firebase.analytics()

// utils
const db = Firebase.database()
const firestore = Firebase.firestore()

// export utils/refs
export {
  db,
  firestore
}
