<template>
  <v-app>
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
<!--
    <v-app-bar
      app
      color="light green"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
-->

    <v-main>
      <router-view/>
    </v-main>
    <v-footer padless dark>
      <v-col class="text-center" cols="12" >

        Made in <strong>Brisbane</strong> with
        <v-icon color="red"  >mdi-heart</v-icon>  {{ new Date().getFullYear() }}
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Jab Finder | Easy way to find Covid Vaccine in Australia',
    meta: [
      { name: 'description', content: 'Jab Finder is here to help you find Pfizer vaccine availability at your nearest clinic withing next 7 days' },
      { property: 'og:title', content: 'Jab Finder | Easy way to find Covid Vaccine in Australia' },
      { property: 'og:site_name', content: 'Jab Finder' },
      { property: 'og:type', content: 'website' },
      { name: 'robots', content: 'index,follow' },
      { name: 'geo.region', content: 'Australia' }
    ]
  }

}
</script>

<style>
.grecaptcha-badge {
  visibility: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 20px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
